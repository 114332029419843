import React, { FunctionComponent, useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PrimaryButton } from '@pricespider-neuintel/pawprint'

import { UserContext } from '../context/UserContext'
import { api } from '../api'

export const Login: FunctionComponent = props => {
    const { setUser } = useContext(UserContext)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)

    let history = useHistory()
    let location = useLocation()

    const submit = () => {
        const login = async () => {
            try {
                const check = await api.post('oauth/token', {
                    grant_type: 'password',
                    client_id: process.env.REACT_APP_CLIENT_ID,
                    client_secret: process.env.REACT_APP_CLIENT_SECRET,
                    username,
                    password
                })

                const token = check.data.access_token

                api.defaults.headers.common.Authorization = `Bearer ${token}`

                const user = await api.get('api/user')
                setUser({ ...user.data, token })

                let { from } = location.state || { from: { pathname: '/' } }
                history.replace(from)
            } catch (error) {
                console.log(error)
                setError(error)
            }
        }

        login()
    }
    return (
        <div>
            <h2 className="text-2xl">Login</h2>
            <div>
                <label className="mr-4">
                    Username:
                    <input
                        className={`border ml-2 p-1 `.concat(error ? 'border-red' : 'border-secondary-light') }
                        type="text"
                        onChange={e => { setUsername(e.target.value); setError(false) }}
                    />
                </label>
                <label className="mr-4">
                    Password:
                    <input
                        className={`border ml-2 p-1 `.concat(error ? 'border-red' : 'border-secondary-light') }
                        type="password"
                        onChange={e => { setPassword(e.target.value); setError(false) }}
                    />
                </label>
                <PrimaryButton onClick={submit}>Login</PrimaryButton>
            </div>
        </div>
    )
}
