import React, { useReducer, useEffect } from 'react'

export interface UserOptions {
    user_id?: number,
    first_name?: string,
    last_name?: string,
    email?: string,
}
interface UserContextValue {
    user: UserOptions
    setUser: React.Dispatch<UserOptions>
}

let reducer = (user: UserOptions, newUser: UserOptions) => {
    if (newUser === null) {
        localStorage.removeItem('user')
        return initialUser
    }

    if (!newUser.user_id) {
        console.log(`logigng out from ${user.user_id}`)
    }

    return { ...newUser }
}

const initialUser: UserOptions = {}
const storedUser = localStorage.getItem('user')
const localState = storedUser ? JSON.parse(storedUser) : {}

const UserContext = React.createContext({ user: initialUser } as UserContextValue)

const UserProvider: React.FunctionComponent = (props) => {
    const [user, setUser] = useReducer(reducer, localState || initialUser)

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user))
    }, [user])

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }
