import React, { useContext, FunctionComponent } from 'react'
import {
    Navigation,
    Logo,
    Flash,
    GhostButton,
    Button,
    Avatar
} from '@pricespider-neuintel/pawprint'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
    RouteProps
} from 'react-router-dom'
import { UserContext } from './context/UserContext'
import { Login } from './pages/Login'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FunctionComponent<RouteProps> = ({ children, ...rest }) => {
    const { user } = useContext(UserContext)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                user.user_id ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    )
}

const App: FunctionComponent = () => {
    const { user, setUser } = useContext(UserContext)

    return (
        <Router>
            <Navigation className="p-4 items-center">
                <Link to="/" className="text-white inline-block w-56">
                    <Logo className="w-full" style={{ opacity: 0.6 }} />
                </Link>
                {user.user_id ? (
                    <div className="flex">
                        <Avatar
                            firstName={user.first_name}
                            lastName={user.last_name}
                        />
                        <Button onClick={() => setUser({})}>Log Out</Button>
                    </div>
                ) : (
                    <Link to="/login">
                        <Button>Log In</Button>
                    </Link>
                )}
            </Navigation>
            <div className="p-4">
                <ul className="flex">
                    <li className="mr-2">
                        <Link to="/public">
                            <GhostButton>Public Page</GhostButton>
                        </Link>
                    </li>
                    <li className="mr-2">
                        <Link to="/protected">
                            <GhostButton>Protected Page</GhostButton>
                        </Link>
                    </li>
                </ul>

                <div className="my-2">
                    <Switch>
                        <Route path="/public">
                            <h1 className="text-2xl">Public</h1>
                        </Route>
                        <Route path="/login">
                            {user.email ? <Flash type="info">Logged In!</Flash> : <div><Flash type='warning'>You must be logged in to proceed.</Flash><Login /></div>}
                        </Route>
                        <PrivateRoute path="/protected">
                            <h1 className="text-2xl">
                                You can see Protected View now!
                            </h1>
                        </PrivateRoute>
                    </Switch>
                </div>
            </div>
        </Router>
    )
}

export default App