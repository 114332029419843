import React from 'react'
import ReactDOM from 'react-dom'
import '@pricespider-neuintel/pawprint/dist/pawprint.css'

import { UserProvider } from './context/UserContext'
import App from './App'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <UserProvider>
        <App />
    </UserProvider>,
    document.getElementById('root')
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
